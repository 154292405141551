
  import { SmartBannerConfig } from './index'
  export const config: SmartBannerConfig = {
    festivalName: 'La Tannerie',
    appTitle: 'La Tannerie',
    appStoreId: '0',
    appleStoreUrl: 'https://apps.apple.com/fr/app/la-tannerie/id6444757569',
    googleStoreUrl: 'https://play.google.com/store/apps/details?id=to.chapi.tannerie&hl=ln',
    appIconUrl: 'https://static.tannerie.chapi.to/app-icon.jpeg',
  }
