import SmartBanner from 'smartbanner.js/src/smartbanner'
import './smartbanner.css'
import { config } from './config'
import type { i18n } from './i18n'
import { getUserPreferedTranslationTable } from './i18n'
import emptyStar from './assets/empty-star.svg?as-text'
import halfStar from './assets/half-star.svg?as-text'
import fullStar from './assets/full-star.svg?as-text'

export type MobilePlatform = 'ios' | 'android'
export type SmartBannerConfig = {
  festivalName: string
  appTitle: string
  appStoreId: string
  appleStoreUrl: string
  googleStoreUrl: string
  appIconUrl: string

  forcedPlatform?: MobilePlatform
  parentElementId?: string
  disableInteractions?: boolean
  forcedStarsCount?: number
}

type AppStoreData = {
  resultCount: number
  results: {
    averageUserRating: number
    // missing lots of unused fields
  }[]
}

// the <script src="smartbanner.js"> element, must be cached globally
const embeddingScriptElement: HTMLScriptElement | null = document.currentScript as HTMLScriptElement | null

function getPreviewConfig(): SmartBannerConfig | undefined {
  if (!embeddingScriptElement) return undefined
  const configAttr: string | null = embeddingScriptElement.getAttribute('preview-config')
  return configAttr ? JSON.parse(configAttr) : undefined
}

async function getAppleStoreStars(config: Pick<SmartBannerConfig, 'forcedStarsCount' | 'appStoreId'>): Promise<number | undefined> {
  return (
    config.forcedStarsCount ??
    (await fetch(`http://itunes.apple.com/lookup?id=${config.appStoreId}`)
      .then((res) => res.json())
      .then((json: AppStoreData) => json.results[0]?.averageUserRating)
      .catch(() => undefined))
  )
}

function starsToHtml(starCount: number): string {
  return (
    Array(5)
      .fill(0)
      .map((_, i): string => (starCount >= i + 1 ? fullStar : starCount >= i + 0.5 ? halfStar : emptyStar))
      .join('') + '<br>'
  )
}

class ChapitoSmartBanner extends SmartBanner {
  get html(): string {
    // The SmartBanner#html getter is used by SmartBanner#publish()
    // to generate the actual DOM element. Here we replace it to
    // make it compatible with a css grid layout (basically we
    // drop all intermediary elements to make everything a child
    // of .smartbanner) and tweak a few css classes to avoid conflicts
    // with popular CMSs
    // prettier-ignore
    return (`
      <div class="chapito-smartbanner smartbanner--${this.platform ?? 'ios'} js_smartbanner">
        <a href="javascript:void(0);" class="smartbanner__exit js_smartbanner__exit" aria-label="${this.closeLabel}"></a>
        <div class="smartbanner__icon" style="background-image: url(${this.icon});"></div>
        <div class="smartbanner__info__title">${this.options.title}</div>
        <div class="smartbanner__info__author">${this.options.author}</div>
        <div class="smartbanner__info__price">${this.options.price}${this.priceSuffix}</div>
        <a href="${this.buttonUrl}" target="_blank" class="smartbanner__button js_smartbanner__button" rel="noopener" aria-label="${this.options.button}"><span class="smartbanner__button__label">${this.options.button}</span></a>
      </div>
    `);
  }
}

async function showBanner(): Promise<void> {
  const t: i18n = getUserPreferedTranslationTable()
  const usedConfig: SmartBannerConfig = getPreviewConfig() /* backoffice */ ?? config /* static build, or during dev. */

  // this is assuming there is no XSS in the translation tables and config
  const smartbanner = new ChapitoSmartBanner()
  smartbanner.platform = usedConfig.forcedPlatform ?? smartbanner.platform
  smartbanner.options = {
    enabledPlatforms: 'android,ios',
    author: usedConfig.festivalName,
    price: '',
    button: t['button'],
    closeLabel: t['close-label'],
    priceSuffixApple: t['price-suffix-apple'],
    priceSuffixGoogle: t['price-suffix-google'],
    title: usedConfig.appTitle,
    iconApple: usedConfig.appIconUrl,
    iconGoogle: usedConfig.appIconUrl,
    buttonUrlApple: usedConfig.appleStoreUrl,
    buttonUrlGoogle: usedConfig.googleStoreUrl,
    disablePositioning: usedConfig.parentElementId ? 'true' : undefined,
  }

  if (smartbanner.platform === 'ios') {
    const stars: number | undefined = await getAppleStoreStars(config)
    if (stars !== undefined) smartbanner.options.priceSuffixApple = `${starsToHtml(stars)}${smartbanner.options.priceSuffixApple}`
  }

  document.addEventListener('smartbanner.clickout', () => {
    const smartBannerElement = document.querySelector('.chapito-smartbanner')
    if (smartBannerElement) document.body.appendChild(smartBannerElement)
    smartbanner.exit()
  })
  if (usedConfig.forcedPlatform) document.cookie = 'smartbanner_exited=0'
  smartbanner.publish()

  const smartBannerElement = document.querySelector('.chapito-smartbanner') as HTMLDivElement
  if (!smartBannerElement) throw new Error('Expected banner to be present')
  if (usedConfig.disableInteractions) smartBannerElement.style.pointerEvents = 'none'
  if (usedConfig.parentElementId) document.getElementById(usedConfig.parentElementId)?.appendChild(smartBannerElement)
}

// add an event as a way of showing the banner back up if it was
// closed, without reloading the script (mainly for backoffice uses)
document.addEventListener('chapito-smartbanner-show', () => {
  showBanner()
})
// kickstart the banner on script load
showBanner()
