// not using separate .json files because there are to few keys for a proper architecture to be relevant

import { includes, keys } from '../../services/typescript'

export type i18n = {
  button: string
  'close-label': string
  'price-suffix-apple': string
  'price-suffix-google': string
}

const i18nFr: i18n = {
  button: 'OBTENIR',
  'price-suffix-apple': "OBTENIR - sur l'App Store",
  'price-suffix-google': 'OBTENIR - sur Google Play',
  'close-label': 'Fermer',
}

const i18nEn: i18n = {
  button: 'VIEW',
  'price-suffix-apple': 'VIEW - On the App Store',
  'price-suffix-google': 'VIEW - On Google Play',
  'close-label': 'Close',
}

type Lang = 'fr' | 'en'

const translationTables: Record<Lang, i18n> = {
  fr: i18nFr,
  en: i18nEn,
} as const

function isKnownLang(lang: string): lang is Lang {
  return includes(keys(translationTables), lang)
}

export function getUserPreferedTranslationTable(): i18n {
  const userLangs: string[] = navigator.languages.flatMap((l) => l.toLocaleLowerCase().split('-'))
  const preferedAvailableLang: Lang = userLangs.find(isKnownLang) ?? 'fr'
  return translationTables[preferedAvailableLang]
}
